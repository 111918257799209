<template>
  <div>
    <div class="card-body">
      <div class="container">
        <ul v-if="logistics.length" class="nav nav-tabs">
          <li
            v-for="(logistic, index) in $v.logistics.$each.$iter"
            :key="index"
            class="nav-item">
            <div class="d-flex justify-space-between">
              <a
                class="nav-link"
                :class="{'active': tab === index}"
                @click="tab = index"><i v-if="logistic.$error" class="mr-1 text-danger fe fe-alert-circle"></i>{{(logistic.alternative_uom.$model || {}).name || `New ${parseInt(index) + 1}`}}</a>
              <button
                v-if="edit"
                class="btn btn-link"
                @click.prevent="removeLogistics(index)">
                <i class="fe fe-x-circle"></i>
              </button>
            </div>
          </li>
          <li v-if="edit && nextLogisticUom" class="nav-item">
            <a class="nav-link text-center" @click="addNewLogistics">Add <i class="fe fe-plus w-100"></i></a>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div
          v-for="(logistic, index) in $v.logistics.$each.$iter"
          :key="index"
          class="tab-pane fade"
          :class="tab === index ? 'show active' : ''">
          <template v-if="edit">
            <div class="mt-4">
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Alternative unit of measure <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <select
                    v-model="logistics[parseInt(index)].alternative_uom"
                    :class="{'is-invalid': logistic.alternative_uom.$error}"
                    class="form-control custom-select"
                  >
                    <option value="">Select alternative uom</option>
                    <option
                      v-for="unit in filteredUomLogistics"
                      :key="unit.id"
                      :value="unit">
                      {{unit.name}}
                    </option>
                  </select>
                  <template v-if="logistic.alternative_uom.$dirty">
                    <div v-if="!logistic.alternative_uom.required" class="invalid-feedback d-block">Alternative unit of measure is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Barcode (GTIN) <span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <input
                    v-model="logistics[parseInt(index)].barcode_gtin"
                    :class="{'is-invalid': logistic.barcode_gtin.$error}"
                    :disabled="logistics[parseInt(index)].generate_hc_code"
                    class="form-control"
                    type="text">
                  <template v-if="logistic.barcode_gtin.$dirty">
                    <div v-if="!logistic.barcode_gtin.required" class="invalid-feedback d-block">Barcode GTIN is required</div>
                  </template>
                </div>
                <label
                  v-b-tooltip="'Enable only if the article does not have a barcode like vegetables.'"
                  class="custom-switch mt-2"
                >
                  <input
                    v-model.number="logistics[parseInt(index)].generate_hc_code"
                    type="checkbox"
                    class="custom-switch-input"
                  >
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description"></span>
                  Generate barcode
                </label>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Dimensions <small class="text-muted">(in millimeter)</small><span class="text-danger">*</span></label>
                <div class="col-sm-8">
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Length</div>
                        </div>
                        <input
                          v-model="logistics[parseInt(index)].length"
                          :class="{'is-invalid': logistic.length.$error}"
                          class="form-control"
                          type="number"
                          step="any">
                      </div>
                      <template v-if="logistic.length.$dirty">
                        <div v-if="!logistic.length.required" class="invalid-feedback d-block">Length is required</div>
                      </template>
                    </div>
                    <div class="col-sm-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Width</div>
                        </div>
                        <input
                          v-model="logistics[parseInt(index)].width"
                          :class="{'is-invalid': logistic.width.$error}"
                          class="form-control"
                          type="number"
                          step="any">
                      </div>
                      <template v-if="logistic.width.$dirty">
                        <div v-if="!logistic.width.required" class="invalid-feedback d-block">Width is required</div>
                      </template>
                    </div>
                    <div class="col-sm-4">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <div class="input-group-text">Height</div>
                        </div>
                        <input
                          v-model="logistics[parseInt(index)].height"
                          :class="{'is-invalid': logistic.height.$error}"
                          class="form-control"
                          type="number"
                          step="any">
                      </div>
                      <template v-if="logistic.height.$dirty">
                        <div v-if="!logistic.height.required" class="invalid-feedback d-block">Height is required</div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Volume <small class="text-muted">(L x W x H)</small><span class="text-danger">*</span></label>
                <div class="col-sm-6">
                  <div class="input-group">
                    <input
                      v-model="logistics[parseInt(index)].volume"
                      class="form-control"
                      type="number"
                      step="any"
                      placeholder="0"
                      readonly>
                    <div class="input-group-append">
                      <div class="input-group-text">mm<sup>3</sup></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Pack size <span class="text-danger">*</span></label>
                <div class="col-sm-3">
                  <input
                    v-model="logistics[parseInt(index)].weight_factor"
                    :class="{'is-invalid': logistic.weight_factor.$error}"
                    class="form-control"
                    type="number"
                    step="any">
                  <template v-if="logistic.weight_factor.$dirty">
                    <div v-if="!logistic.weight_factor.required" class="invalid-feedback d-block">Weight factor is required</div>
                  </template>
                </div>
              </div>
              <div class="form-group row d-flex">
                <label class="col-sm-3 col-form-label text-right font-weight-bold">Gross weight <span class="text-danger">*</span></label>
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-3 pr-1">
                      <input
                        v-model="logistics[parseInt(index)].gross_weight"
                        :class="{'is-invalid': logistic.gross_weight.$error}"
                        type="number"
                        step="any"
                        class="form-control"
                        placeholder="Enter weight">
                    </div>
                    <div class="col-auto pl-0">
                      <select
                        v-model="logistics[parseInt(index)].weight_uom"
                        class="form-control custom-select"
                        :class="{'is-invalid': logistic.weight_uom.$error}">
                        <option :value="null">Select unit</option>
                        <option
                          v-for="unit in uomNetWeights"
                          :key="unit.id"
                          :value="unit">
                          {{unit.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <template v-if="logistic.gross_weight.$dirty">
                    <div v-if="!logistic.gross_weight.required" class="invalid-feedback d-block">Gross weight is required</div>
                  </template>
                  <template v-if="logistic.weight_uom.$dirty">
                    <div v-if="!logistic.weight_uom.required" class="invalid-feedback d-block">Gross weight unit of measure is required</div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="mt-4">
              <form-description label="Alternative unit of measure" :value="(logistic.alternative_uom.$model || {}).name"/>
              <form-description
                label="Barcode (GTIN)"
                :value="logistic.barcode_gtin.$model"/>
              <form-description label="Dimensions (in mm)" :value="`${logistic.length.$model} x ${logistic.width.$model} x ${logistic.height.$model}`"/>
              <form-description label="Volume (L x W x H)" :value="`${logistics[parseInt(index)].volume} mm&#xB3;`"/>
              <form-description label="Pack size" :value="numeral(logistic.weight_factor.$model).format('0,0[.]00')"/>
              <form-description label="Gross weight" :value="`${logistic.gross_weight.$model} ${(logistic.weight_uom.$model || {}).name}`"/>
            </div>
          </template>
        </div>
      </div>
      <div v-if="!value.logistics.length" class="text-center">
        <button
          v-if="edit"
          class="btn btn-primary"
          @click.prevent="addNewLogistics">
          Add new logistics
        </button>
        <div v-else class="text-center">No logistics available</div>
      </div>
      <template v-if="$v.$anyDirty && $v.$anyError">
        <div v-if="$v.$anyError" class="invalid-feedback d-block">Logistics is required</div>
      </template>
    </div>
    <div v-if="edit" class="card-footer d-flex">
      <button class="btn btn-primary ml-auto" @click.prevent="validate">Apply</button>
    </div>
  </div>

</template>

<script>

import {weekDays} from '@/services';
import {required, requiredIf, minLength} from 'vuelidate/lib/validators';
import FormDescription from '@/components/FormDescription';

export default {

  components: {

    FormDescription,
  },
  props: {

    edit: {

      default: true,
      type: Boolean,
    },
    uom: {

      required: true,
      type: Array,
    },
    value: {

      required: true,
      type: Object,
    },
  },
  data() {
    return {

      tab: '0',
      weekDays: [],
    };
  },
  computed: {

    filteredUomLogistics() {
      return this.uomLogistics.filter(item => item.logistics_units_order_rank >= this.logisticsRank);
    },
    lastLogisticUom() {
      return this.logistics[this.logistics.length - 1]?.alternative_uom || {};
    },
    logistics() {
      return this.value.logistics.map(item => {
        return Object.assign(item, {

          volume: item.length * item.width * item.height,
        });
      });
    },
    logisticsRank() {
      return this.logistics[parseInt(this.tab)]?.alternative_uom?.logistics_units_order_rank || 0;
    },
    nextLogisticUom() {
      return this.filteredUomLogistics.find(item => item.logistics_units_order_rank > (this.lastLogisticUom?.logistics_units_order_rank || -1)) || null;
    },
    uomLogistics() {
      return this.uom.filter(value => value.is_for_logistics).sort((oldValue, newValue) => oldValue.logistics_units_order_rank - newValue.logistics_units_order_rank);
    },
    uomNetWeights() {
      return this.uom.filter(value => value.is_for_article_net_weight);
    },
  },
  created() {
    weekDays.getByParameters({column: 'name', direction: 'asc'}).then(result => this.weekDays = result.items);
  },
  methods: {

    addNewLogistics() {
      this.value.logistics.push({

        alternative_uom: this.nextLogisticUom,
        weight_uom: null,
      });

      this.tab = (this.value.logistics.length - 1).toString();
    },
    removeLogistics(index) {
      this.value.logistics.splice(parseInt(index), 1);

      const previousIndex = this.value.logistics.length - 1;

      this.tab = previousIndex.toString();
    },
    validate() {
      this.$v.logistics.$touch();

      if (!this.$v.logistics.$invalid) { this.$emit('valid'); }
    },
  },
  validations: {
    logistics: {
      $each: {
        alternative_uom: {
          required,
        },
        barcode_gtin: {
          minLength: minLength(8),
          // required only if the user is not choosing to generate a barcode
          required: requiredIf(function(nestedModel) {
            return !nestedModel.generate_hc_code;
          }),
        },
        gross_weight: {
          required,
        },
        height: {
          required,
        },
        length: {
          required,
        },
        weight_factor: {
          required,
        },
        weight_uom: {
          required,
        },
        width: {
          required,
        },
      },
    },
  },
};
</script>
